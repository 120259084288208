import React from 'react'
import { DESCRIPTIONS } from '../../helpers/constants'
import Layout from '../../components/nigeria/layout'
import { Link, Trans } from "gatsby-plugin-react-i18next";
import SpecialBg from "../../images/nigeria/special-bg.png";
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

const CookiePolicy = () => {
  return (
    <Layout pageTitle="Cookie Policy | Moniepoint MFB">
      <Helmet>
        <link rel="alternate" href="https://moniepoint.com/ng/cookie-policy" hreflang="x-default" />
        <link rel="alternate" href="https://moniepoint.com/ng/cookie-policy" hreflang="en-ng" />
      </Helmet>
      <GetStartedContainer>
        <div className="bg-mask"></div>
        <div className="content">
          <div className="get-started-section">
            <h2 className={`title`}>
              <Trans>Cookie Policy</Trans>
            </h2>
            <p className="description">
              <Trans>This Cookie Policy describes what cookies are, how Moniepoint MFB uses them on the applicable digital channels known as the Moniepoint MFB bouquet of products and what benefits they bring</Trans>
            </p>
          </div>
        </div>
      </GetStartedContainer>
      <ContentContainer>
        <div className="content">
          <div className="cp-list">
            <div className="cp-list-outer">
              <h1 className="cp-list-header">Cookies: What are they?</h1>
              <p className="cp-list-copy top">
                A "cookie" is a small text file that is stored on your
                computer, tablet or phone when you visit a website. Some
                cookies are deleted when you close your broswer. These are
                known as session cookies . Others remain on your device until
                they expire, or you delete them from your cache. These are
                known as persistent cookies and enable us to remember things
                about you as a returning visitor. To find out more about
                cookies, including how to see what cookies have been set and
                how to manage and delete them, visit{' '}
                <a target='_blank' href='https://www.allaboutcookies.org/'>https://www.allaboutcookies.org/.</a> By clicking and
                opening this link you are migrating from the Moniepoint MFB secure
                site to a third-party website.
              </p>
              <p className="cp-list-copy">
                We make no representation as to the security features on the
                site or the level of security available on the site. It is
                your responsibility to protect your device or system through
                which you access the third party's website. Alternatively, you
                can search the internet for other independent information on
                cookies.
              </p>
            </div>
            <div className="cp-list-outer">
              <h1 className="cp-list-header">Cookies: How we use them</h1>
              <p className="cp-list-copy">
                If you delete cookies relating to this website we will not
                remember things about you, including your cookie preferences,
                and you will be treated as a first-time visitor the next time
                you visit the site. We use cookies (and other similar
                technologies) to:
                <ul className="cp-list inner">
                  <li>
                    Provide products and services that you request and to
                    provide a secure online environment{' '}
                  </li>
                  <li>Manage our marketing relationships.</li>
                  <li>Improve the performance of our services</li>
                  <li>
                    Help us decide which of our products, services and offers
                    may be relevant for your need
                  </li>
                  <li>
                    Give you a better online experience and track website
                    performance
                  </li>
                  <li>Help us make our website more relevant to you</li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </ContentContainer>
    </Layout>
  )
}

export default CookiePolicy


export const query = graphql`
query {
    locales: allLocale(
      filter: { ns: { in: ["common", "nigeria"] }, language: { eq: "en" } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const GetStartedContainer = styled.section`
  background: rgba(3, 87, 238, 1);
  background-size: fill;
  background-repeat: no-repeat;
  background-position: 50% 25%;
  color: white;
  position: relative;

  .bg-mask {
    background: url(${SpecialBg});
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: inherit;
    opacity: 0.4;
  }
  .content {
    max-width: 1440px;
    margin: auto;
    padding: 81px 151px 101px;
    position: relative;
    @media only screen and (max-width: 1024px) {
      padding: 81px 60px 101px;
    }

    .get-started-section {
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        font-family: "Founders Grotesk";
        font-size: 58px;
        font-weight: 500;
        line-height: 66px;
        letter-spacing: 0em;
        text-align: center;
        max-width: 657px;
        margin-bottom: 8px;
      }

      .description {
        max-width: 623px;
        font-size: 16px;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: center;
      }

      .get-started-button {
        background: rgba(255, 255, 255, 0.3);
        backdrop-filter: blur(8px);
        margin: 0 auto;
        padding: 16px 24px;
        border: none;
      }
    }
  }
  @media only screen and (max-width: 1024px) {
    .content {
      padding: 81px 30px 98px;
      .get-started-section {
        .title {
          font-size: 32px;
          line-height: 35.04px;
          margin-bottom: 16px;
        }

        .description {
          font-size: 16px;
          line-height: 26px;
          text-align: center;
        }
      }
    }
  }
`;

const ContentContainer = styled.section`
  .content {
    max-width: 1440px;
    margin: auto;
    padding: 81px 151px 101px;
    position: relative;
    @media only screen and (max-width: 1024px) {
      padding: 81px 60px 101px;
    }



  .cp-list {
    padding-left: 0;

    &.inner {
      margin-top: 8px;
      padding-left: 30px;
      list-style-position: inside;
    }

    ul {
      list-style-type: disc;
    }

    &.sub {
      list-style-type: circle;
    }
  }

  .cp-list-outer {
    margin-bottom: 40px;
    list-style-position: inside;

    &::marker {
      font-size: 32px;
      font-weight: bold;
    }
  }

  .cp-list-header {
    font-family: "Founders Grotesk";
    font-style: normal;
    font-weight: bolder;
    font-size: 32px;
    line-height: 38px;
    color: rgba(0, 0, 0, 1);
    margin-bottom: 8px;
    display: inline-block;
    line-height: 1.5;

    @media only screen and (max-width: 768px) {
      font-size: 24px;
    }
  }

  .cp-list-copy {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.7);
    opacity: 0.8;
    margin-bottom: 12px;

    &.top {
      margin-top: 10px;
    }

    b {
        color: rgba(0, 0, 0, 0.9);
    }
  }

  .cp-list-sub {
    padding-left: 0;
    counter-reset: item;
  }

  .cp-list-header {
    font-family: "Founders Grotesk";
    font-weight: 500;
    font-size: 24px;
    color: rgba(0, 0, 0, 1);
    margin-bottom: 8px;
    display: inline-block;

    @media only screen and (max-width: 768px) {
      font-size: 20px;
    }
  }

  li > b {
    color: rgba(0, 0, 0, 1);
  }

  .cp-list-outer-sub {
    margin-bottom: 16px;

    ul ul {
      li {
        list-style-type: circle;
      }
    }
  }

  ol > li {
    counter-increment: item;
  }

  ol.cp-list-sub > li {
    display: block;
  }

  ol.cp-list-sub > li:before {
    font-size: 24px;
    font-weight: 500;
    content: counters(item, ".") ". ";

    @media only screen and (max-width: 768px) {
      font-size: 20px;
    }
  }

  .cp-table {
    margin: 16px auto;
  border: 1px solid;

  th, td {
    border: 1px solid;
    padding: 6px;
    font-size: 14px;
    }
  }


  .image-container {
    display: flex;
    gap: 16px;

    .cp-image {
      width: 100%;
      height: 100%;
      max-width: 512px;
    }

    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }
  }
  }

  @media only screen and (max-width: 768px) {
    .content {
      padding: 48px 30px;
    }}
`;